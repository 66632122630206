import React from 'react';

function Footer() {
  return (
    <footer className="bg-gray-800 text-white p-4 text-center mt-2">
      <p>© {new Date().getFullYear()} Sitaram Yadav. All rights reserved.</p>
      <nav>
       
      </nav>
    </footer>
  );
}

export default Footer;
